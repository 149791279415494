<template>
  <div class="index">
    <div class="container">
      <!-- 主容器，分左右，右边长列表 -->
      <div class="i-con">
        <!-- 左侧长内容 -->
        <div class="i-l">
          <!-- 头部 -->
          <div class="s-header">
            <h2>{{ this.title }}</h2>
            <span>{{ this.author }}</span>
            <span>发布: {{ this.time }} </span>
            <span>{{ this.sub }}阅读</span>
          </div>

          <!-- 内容 -->
          <div class="s-con">
            <!-- 文字 -->
            <div class="s-text">
              <p>{{ this.article1 }}</p>
              <!-- 图片 -->
              <img :src="this.img" alt="" />
              <p>{{ this.article2 }}</p>
            </div>
          </div>
        </div>

        <!-- 右侧长列表 -->
        <div class="i-r">
          <!-- 资讯1 -->
          <div class="a1">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>推荐文章</h2>
            </div>
            <!-- 循环项 -->
            <div
              class="a1-item"
              v-for="(item, index) in this.d1"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <i class="iconfont icon-jinru"></i>
              <!-- 信息 -->
              <div class="item-info">
                {{ item.title }}
              </div>
            </div>
          </div>
          <!-- banner 区块 -->
          <div class="banner">
            <img src="/imgs/b2.jpg" alt="" />
          </div>
          <div class="banner">
            <img src="/imgs/b3.jpg" alt="" />
          </div>
          <!-- 资讯1 -->
          <div class="a1">
            <!-- 标题 -->
            <div class="a1-title">
              <h2>推荐文章</h2>
            </div>
            <!-- 循环项 -->
            <div
              class="a1-item"
              v-for="(item, index) in this.d2"
              :key="index"
              @click="goDays(item.methods, item.id)"
            >
              <i class="iconfont icon-jinru"></i>
              <!-- 信息 -->
              <div class="item-info">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      href: "index.html",
      // 获取 id
      myId: this.$route.query.type,
      // 获取 标题
      title: "",
      // 简介
      concise: "",
      // 作者
      author: "",
      // 图片路径
      img: "",

      // 文章1
      article1: "",
      // 文章2
      article2: "",
      time: "",
      ac3: [],
      d1: [],
      d2: [],
      d3: [],
      sub: "",
    };
  },
  components: {},
  mounted() {
    this.init();
    this.init2();
    document.title = "北京通广互通网络技术有限公司";
  },
  methods: {
    init() {
      this.axios.get("/mock/ac1.json").then((res) => {
        var vl = res.data;
        var date = vl.find((vl) => vl.id == this.myId);
        console.log(date);
        // console.log(date)
        // 标题
        this.title = date.title;
        // 作者
        this.author = date.author;
        // 歌词
        this.concise = date.concise;
        // 图片路径
        this.img = date.img;
        // 文章 1
        this.article1 = date.article1;
        // 文章 2
        this.article2 = date.article2;
        this.time = date.time;
        this.ac3 = vl.slice(0, 7);
        this.sub = date.sub;
      });
    },
    init2() {
      this.axios.get("/mock/ac1.json").then((res) => {
        const data = res.data;
        // 两条数据
        this.d1 = data.slice(0, 5);
        this.d2 = data.slice(4, 10);
        this.d3 = data.slice(8, 13);
        // console.log(this.data);
      });
    },
    // 跳转功能
    goDays(methodsWords, id) {
      this[methodsWords](id);
    },
    // 跳转详情页
    goDetails(id) {
      this.$router.push({
        path: "/sound/:" + id,
        query: { type: id },
      });
      location.reload();
    },
    // 收费逻辑
    gopay() {
      // console.log('1')

      if (this.state == 0) {
        // this.$router.push("/pay");
        this.showModal = true;
        console.log(this.showModal);
      }
      // 未登录
      if (this.state !== 0) {
        alert("资讯每条3元，点击确定注册购买");
        this.$router.push({ path: "/register", query: { k: 1 } });
        return;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./../assets/scss/base.scss";
@import "./../assets/scss/config.scss";
@import "./../assets/scss/mixin.scss";
.index {
  background-color: #eee;
  padding: 20px 0;
  box-sizing: border-box;
  .container {
    // 主容器1
    .i-con {
      display: flex;
      justify-content: space-between;
      width: 100%;
      // height: 3000px;
      // background-color: pink;
      // margin: 160px auto 60px;
      // 左侧
      .i-l {
        padding: 20px;
        width: 70%;
        height: 100%;
        background-color: #fff;
        box-sizing: border-box;

        // 头部
        .s-header {
          width: 100%;
          height: 100px;
          background-color: #fff;
          text-align: center;
          h2 {
            margin-bottom: 30px;
          }
          span {
            font-size: 14px;
            margin: 0 15px;
          }
        }
        // 文章容器
        .s-con {
          // 图片
          img {
            width: 100%;
            height: 400px;
            margin: 30px auto;
            background-color: pink;
          }
          // 文章
          .s-text {
            width: 100%;
            text-align: left;
            padding: 20px;
            box-sizing: border-box;
            p {
              margin: 20px auto;
              font-size: 14px;
              line-height: 70px;
            }
          }
        }
      }

      // 右侧
      .i-r {
        width: 30%;
        height: 100%;
        // background-color: #c60023;
        box-sizing: border-box;
        margin-left: 30px;

        // 资讯1
        .a1 {
          width: 100%;
          // height: 500px;
          background-color: #fff;
          // margin: 30px 0;
          margin-bottom: 30px;

          // 标题
          .a1-title {
            width: 100%;
            height: 50px;
            border-left: 3px solid $colorZ;
            padding-left: 30px;
            box-sizing: border-box;
            h2 {
              line-height: 50px;
              font-weight: 400;
              color: #333;
            }
          }

          // 循环项
          .a1-item {
            width: 100%;
            height: 30px;
            // background: #ff6600;
            margin: 15px 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0 10px;
            box-sizing: border-box;
            &:hover {
              .item-info {
                color: $colorZ;
              }
            }
            i {
              font-size: 21px;
              margin-right: 15px;
            }
            .item-info {
              width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              transition: all 0.2s;
            }
          }
        }
        // banner
        .banner {
          width: 100%;
          height: 250px;
          background-color: #fff;
          margin: 20px 0;
        }
      }
    }
  }
}
</style>
